@import "../base/_variables.scss";

%page {
    height: 400px;
    position: relative;
    background: #24353f;

    .background {
        background-position-y: 30%;
        opacity: 0.8;
        mix-blend-mode: hard-light;
    }

    .foreground-middle h2 {
        font-size: 4em;
    }
}

header {
    .background {
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }


    &.home {
        &.home-offline {
            .background {
                background-position: center;
                background-image: url('/images/welcome-header-700w.webp');
                opacity: 1;
                background-size: cover;
            }

            .foreground {
                position: absolute;
            }
        }

        // height: 100vh;
        // height: 90vh;
        // min-height: 100px;
        min-height: 100vh;
        position: relative;
        // background: #2980b9;
        background: #0a2c42;

        .background {
            // background-position: center;
            // background-image: url(/images/home-header.jpg);
            // opacity: 1;
            // background-size: cover;
            opacity: 0;
        }

        .foreground {
            position: relative;
        }

        .foreground-middle .buttonLink {
            margin-top: 20px;
        }

        &.home-long-text .foreground-middle .content {
            p {
                font-size: 1rem;
                text-indent: 4rem;
                margin-bottom: 2rem;
            }
        }

        iframe {
            margin: 4rem 0;
            width: 100%;
            min-height: 720px;
            height: 100%;
        }
    }

    &.about {
        @extend %page;
    }

    &.vbs {
        @extend %page;
    }

    &.media {
        @extend %page;
    }

    &.blog {
        @extend %page;
    }

    &.presents {
        @extend %page;
    }

    .foreground {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
    }

    .foreground-middle {
        small {
            font-weight: 100;
            font-size: 1.4em;
            color: $white;
        }

        p {
            font-weight: 300;
            font-size: 1.6em;
            color: $white;
        }

        button {
            margin-top: 50px;
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        h2 {
            color: $white;
            font-family: $secondary-font;
            text-transform: uppercase;
            margin: 30px 0;
            text-align: center;

            &:before {
                content: none;
            }
        }

    }

    .foreground-bottom {
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        padding: 20px;
        color: $white;
        display: flex;
        height: 90px;
        justify-content: center;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .description {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex: 1;
        }
    }

    .foreground-top .content {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }
}

header.blog .background {
    background-image: url('/images/home-header-3000w.webp');
}

header.presents {
    background: rgba(0, 0, 0, 1);
    height: 40vh;
    min-height: 300px;

    .background {
        mix-blend-mode: normal;
        background-position: center;
        background-size: cover;
        background-image: url('/images/cadou2.jpg');
    }   
}

header.vbs {
    height: 600px;
    margin-bottom: 80px;

    .background {
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/images/vbs-header.jpg');
        opacity: 1;
        mix-blend-mode: normal;
        background-position-x: center;
        background-position-y: center;
        top: 90px;
    }
}

@media screen and (min-width: $break-large) {

    header.home .background {
        // background-image: url('/images/home-header-3000w.webp');
        // background-image: url('/images/welcome-header-3000w.webp');
    }


    header.home.home-offline .background {
        background-image: url('/images/welcome-header-3000w.webp');
    }

    header.about .background,
    header.media .background {
        background-image: url('/images/about-header-3000w.webp');
    }

    header .foreground-middle .content,
    header .foreground-top .content,
    header .foreground-bottom .content {
        width: $width-large;
    }

    header.home .foreground-middle h2 {
        font-size: 6em;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    header.home .background {
        // background-image: url('/images/home-header-3000w.webp');
        // background-image: url('/images/welcome-header-3000w.webp');
    }

    header.home.home-offline .background {
        background-image: url('/images/welcome-header-3000w.webp');
    }

    header.about .background,
    header.media .background {
        background-image: url('/images/about-header-3000w.webp');
    }

    header .foreground-middle .content,
    header .foreground-top .content,
    header .foreground-bottom .content {
        width: $width-medium;
    }

    header.home .foreground-middle h2 {
        font-size: 5em;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    header.home .background {
        // background-image: url('/images/home-header-1000w.webp');
        // background-image: url('/images/welcome-header-1000w.webp');
    }

    header.home.home-offline .background {
        background-image: url('/images/welcome-header-1000w.webp');
    }

    header.about .background,
    header.media .background {
        background-image: url('/images/about-header-1000w.webp');
    }

    header .foreground-middle .content,
    header .foreground-top .content,
    header .foreground-bottom .content {
        width: $width-small;
    }

    header.home .foreground-middle h2 {
        font-size: 5em;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    header.home .background {
        // background-image: url('/images/home-header-1000w.webp');
        // background-image: url('/images/welcome-header-1000w.webp');
    }

    header.home.home-offline .background {
        background-image: url('/images/welcome-header-1000w.webp');
    }

    header.about .background,
    header.media .background {
        background-image: url('/images/about-header-1000w.webp');
    }

    header .foreground-middle .content,
    header .foreground-top .content,
    header .foreground-bottom .content {
        width: $width-xsmall;
    }

    header.home .foreground-middle h2 {
        font-size: 5em;
    }

    header.presents .foreground-middle .content h2 {
        font-size: 3.2em;
    }
}

@media screen and (max-width: $break-xsmall) {

    header.home .background {
        // background-image: url('/images/home-header-700w.webp');
        // background-image: url('/images/welcome-header-700w.webp');
    }

    header.home.home-offline .background {
        background-image: url('/images/welcome-header-700w.webp');
    }

    header.about .background,
    header.media .background {
        background-image: url('/images/about-header-700w.webp');
    }

    header .foreground-middle .content,
    header .foreground-top .content,
    header .foreground-bottom .content {
        width: 100%;
    }

    header .foreground-top {
        width: 100%;
        padding: 0 20px;
    }

    header.home .foreground-middle p {
        font-size: 1em;
    }

    header.home .foreground-middle small {
        font-size: 1em;
    }

    header.home .foreground-middle h2 {
        font-size: 2em;
    }

    header.presents .foreground-middle .content h2 {
        font-size: 2.2em;
    }
}

@media screen and (min-width: $break-small) {
    header .foreground-bottom {
        .description {
            padding-left: 30px;
        }

        button {
            margin-left: 20px;
        }
    }
}

@media screen and (max-width: $break-small) {
    header .foreground-bottom {
        .description {
            padding-left: 10px;
        }

        button {
            margin-left: 10px;
        }
    }
}